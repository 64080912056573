import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../../utils";
let Digio: any;

function LoanAgreement() {
  const [url, setUrl] = useState("");
  const [authToken] = useSessionStorage("feemonk_data", "");

  const navigate = useNavigate();

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_DASHBOARD_URL}/agreement/generate`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${authToken.value}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data.message === "success") {
  //         setUrl(data.data);
  //       }
  //     });
  // }, [authToken.value]);

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  // useEffect(() => {
  //   const apiInterval = setInterval(() => {
  //     fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken.value}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log(data);
  //         if (data.data.agreementStatus === 3) {
  //           console.log("Status Complete");
  //           navigate("/thank-you");
  //           // navigation.navigate("MandateKYC");
  //         }
  //       });
  //   }, 5000);
  //   return () => {
  //     clearInterval(apiInterval);
  //   };
  // }, [authToken.value, navigate]);

  function loanAgreementHandler() {
    fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/agreement/generate`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result);

            const { email, id } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: "production",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: id,
              digioUserIdentifier: email,
              is_iframe: true,
              callback: (_digio: any) => {
                console.log("ALL: ", _digio);

                // var myHeaders = new Headers();
                // myHeaders.append(
                //   "Authorization",
                //   `Bearer ${authToken && authToken.value}`
                // );
                // myHeaders.append("Content-Type", "application/json");

                // var requestOptions: RequestInit = {
                //   method: "POST",
                //   headers: myHeaders,
                //   redirect: "follow",
                // };

                // fetch(
                //   `${process.env.REACT_APP_DASHBOARD_URL}/selfie/updateDetails?entityId=${entityId}&transactionId=${id}`,
                //   requestOptions
                // )
                //   .then((response) => response.text())
                //   .then((result) => {
                //     console.log(result);
                //     navigate("/mandate");
                //   })
                //   .catch((error) => console.log("error", error));

                if (_digio.error_code === "CANCELLED") {
                  // console.log("Flow cancelled by user");
                  navigate("/mandate");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  navigate("/mandate");
                  throw new Error(_digio.message);
                }

                navigate("/mandate");

                // console.log(_digio);
              },
            });

            // console.log(d);

            d.init();
            d.submit(id, email);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     height: "100vh",
    //   }}
    // >
    //   <iframe
    //     src={url}
    //     title="loan_agreement"
    //     style={{
    //       width: "90vw",
    //       height: "90vh",
    //     }}
    //   ></iframe>
    // </div>
    // <iframe
    //   src={
    //     "https://app.digio.in/#/gateway/login/DID221021144909611SHY3QWI8J3WXAI/OAn2zIpMJ/pallavip@feemonk.com"
    //   }
    // title="loan_agreement"
    // ></iframe>
    // <div>
    //   <button onClick={loanAgreementHandler}>open</button>
    // </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h1>Loan Agreement</h1>
    </div>
  );
}

export default LoanAgreement;
