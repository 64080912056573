import React, { useState } from "react";
import styles from "./index.module.css";
import SideBar from "../../components/molecules/SideBar";
import Button from "../../components/atoms/Button";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import { useSessionStorage } from "../../hooks/useSessionStorage";

function AccountAggregator() {
  const [authToken] = useSessionStorage("feemonk_data", "");
  const [redirectLink, setRedirectLink] = useState("");

  const getAARedirectLink = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${authToken.value}`);

      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/generate`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({}),
        }
      );

      const result = await response.json();

      setRedirectLink(result.data.url);
      window.open(result.data.url, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.container}>
          <h1>Account Aggregator</h1>
          <Button
            text={"Link Your Bank Accounts"}
            onPress={() => {
              getAARedirectLink();
            }}
          />
          {/* {redirectLink && <p>{JSON.stringify(redirectLink)}</p>} */}
        </div>
      </div>
    </div>
  );
}

export default AccountAggregator;
