import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import { useSessionStorage } from "../../hooks/useSessionStorage";
// import { UserInitialState } from "../../redux/slice/userData";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import styles from "./styles.module.css";
let Digio: any;

function DigioMandate() {
  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  const navigate = useNavigate();

  const [authToken] = useSessionStorage("feemonk_data", "");

  // const ckycData = useSelector(
  //   (state: { userData: UserInitialState }) =>
  //     state?.userData?.userData?.ckycData
  // );

  const [radioSelectedAccount, setRadioSelectedAccount] = useState("savings");
  const [radioSelectedMandate, setRadioSelectedMandate] = useState("debit");

  const [state, setState] = useState({
    accountNumber: "",
    ifscCode: "",
  });

  const { accountNumber, ifscCode } = state;

  const handleChange = (key: any, val: any) => {
    setState({ ...state, [key]: val });
  };

  // useEffect(() => {
  //   const apiInterval = setInterval(() => {
  //     fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken.value}`,
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log(data);
  //         if (data.data.loanAgreementStatus === "signed") {
  //           console.log("Status Complete");
  //           navigate("/my-loans");
  //         }
  //       });
  //   }, 10000);

  //   return () => {
  //     clearInterval(apiInterval);
  //   };
  // }, [authToken.value, navigate]);

  async function initiateDigio() {
    fetchJsFromCDN("https://app.digio.in/sdk/v8/digio.js", ["Digio"]).then(
      async (digio: any) => {
        Digio = digio[0];
        let d = new Digio({
          environment: "production",
          callback: async function (t: any) {
            if (t.error_code !== undefined) {
              // console.log("Error", t);
              // alert("failed to register with error :" + t.message);
            } else {
              // console.log("Success", t);
              // alert("register successful for " + t.digio_doc_id);
            }

            // Do API call here
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${authToken.value}`);

            var raw = JSON.stringify({
              account_number: accountNumber,
              mandate_id: t.digio_doc_id,
              ifsc_code: ifscCode,
            });

            var requestOptions: RequestInit = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            const result = await fetch(
              `${process.env.REACT_APP_DASHBOARD_URL}/mandate/store`,
              requestOptions
            );

            const response = await result.json();

            // console.log(response);

            navigate("/mandate");
            // navigate("/mandate");
          },
          is_iframe: true, //open in iframe,
          dg_preferred_auth_type: radioSelectedMandate || "debit", // debit or net_banking
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${authToken.value}`);

        var raw = JSON.stringify({
          accountNumber: accountNumber,
          accountType: radioSelectedAccount,
          customerMobile: authToken.mob,
          ifsc: ifscCode,
          customerName: "Swapnadeep",
          authType: radioSelectedMandate,
        });

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const result = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/mandate/register`,
          requestOptions
        );

        const response = await result.json();

        // console.log(response);
        // console.log(response.id);

        // console.log(d);

        d.init();
        d.submit(response.mandate_id, authToken.mob);
      }
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>Mandate</h1>

        <div className={styles.inputField}>
          <Label text="Account Number" />
          <InputText
            placeholder="Account Number"
            type="number"
            value={accountNumber}
            changeHandler={(e) => handleChange("accountNumber", e.target.value)}
          />
        </div>

        <div className={styles.inputField}>
          <Label text="IFSC Code" />
          <InputText
            placeholder="IFSC Code"
            type="text"
            value={ifscCode}
            changeHandler={(e) => handleChange("ifscCode", e.target.value)}
          />
        </div>
        <div className={styles.inputField}>
          <Label text="Account Type" />
          <div
            onChange={(event) =>
              setRadioSelectedAccount((event.target as HTMLInputElement).value)
            }
            defaultValue="savings"
          >
            <input
              type="radio"
              value="savings"
              name="student"
              checked={radioSelectedAccount === "savings"}
            />{" "}
            Savings
            <input
              type="radio"
              value="current"
              name="student"
              checked={radioSelectedAccount === "current"}
            />{" "}
            Current
          </div>
        </div>
        <div className={styles.inputField}>
          <Label text="How do you want to authenticate your account?" />
          <div
            onChange={(event) =>
              setRadioSelectedMandate((event.target as HTMLInputElement).value)
            }
            defaultValue="debit"
          >
            <input
              type="radio"
              value="net_banking"
              name="Net Banking"
              checked={radioSelectedMandate === "net_banking"}
            />{" "}
            Net Banking
            <input
              type="radio"
              value="debit"
              name="Debit Card"
              checked={radioSelectedMandate === "debit"}
            />{" "}
            Debit Card
          </div>
        </div>
        <Button
          onPress={() => {
            initiateDigio();
          }}
          text="SUBMIT"
        />
      </div>
    </div>
  );
}

export default DigioMandate;
