import React, { useState } from "react";
import Button from "../../../components/atoms/Button";
import InputText from "../../../components/atoms/InputText";
import Label from "../../../components/atoms/Label";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../../utils";
import styles from "./styles.module.css";
import {jwtDecode} from 'jwt-decode'

function EMandate() {
  const [radioSelectedAccount, setRadioSelectedAccount] = useState("SAVINGS");
  const [radioSelectedMandate, setRadioSelectedMandate] =
    useState("NET_BANKING");

  const [state, setState] = useState({
    accountNumber: "",
    ifscCode: "",
  });


  const { accountNumber, ifscCode } = state;

  const [authToken] = useSessionStorage("feemonk_data", "");
  const decode=jwtDecode(authToken.value) as any

  const handleChange = (key: any, val: any) => {
    setState({ ...state, [key]: val });
  };

  function getNachRazorpayUrl() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/nach/register`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${authToken.value}`,
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // TODO: get from use state
        // accountNumber: "50100514289789",
        // ifscCode: "HDFC0001252",
        // accountType: "savings",
        // mandateType: "debitcard",
        accountNumber: accountNumber,
        ifscCode: ifscCode,
        accountType: radioSelectedAccount,
        mandateType: radioSelectedMandate,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "success") {
          // console.log(res.data.url);
          window.open(res.data.url, "_blank", "noopener,noreferrer");

          //   navigation.navigate("NachRazorpay", {
          //     razorpayUrl: res.data.url,
          //   });
        }
      });
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
       { (authToken.value&&decode.agreementStatus===3)?
       <><h1>Mandate</h1>

        <div className={styles.inputField}>
          <Label text="Account Number" />
          <InputText
            placeholder="Account Number"
            type="number"
            value={accountNumber}
            changeHandler={(e) => handleChange("accountNumber", e.target.value)}
          />
        </div>

        <div className={styles.inputField}>
          <Label text="IFSC Code" />
          <InputText
            placeholder="IFSC Code"
            type="text"
            value={ifscCode}
            changeHandler={(e) => handleChange("ifscCode", e.target.value)}
          />
        </div>
        <div className={styles.inputField}>
          <Label text="Account Type" />
          <div
            onChange={(event) =>
              setRadioSelectedAccount((event.target as HTMLInputElement).value)
            }
            defaultValue="SAVINGS"
          >
            <input
              type="radio"
              value="SAVINGS"
              name="student"
              checked={radioSelectedAccount === "SAVINGS"}
            />{" "}
            Savings
            <input
              type="radio"
              value="CUURENT"
              name="student"
              checked={radioSelectedAccount === "CUURENT"}
            />{" "}
            Current
          </div>
        </div>
        <div className={styles.inputField}>
          <Label text="How do you want to authenticate your account?" />
          <div
            onChange={(event) =>
              setRadioSelectedMandate((event.target as HTMLInputElement).value)
            }
            defaultValue="NET_BANKING"
          >
            <input
              type="radio"
              value="NET_BANKING"
              name="Net Banking"
              checked={radioSelectedMandate === "NET_BANKING"}
            />{" "}
            Net Banking
            <input
              type="radio"
              value="DEBIT_CARD"
              name="Debit Card"
              checked={radioSelectedMandate === "DEBIT_CARD"}
            />{" "}
            Debit Card
          </div>
        </div>
        <Button
          onPress={() => {
            // console.log("Hello");

            // navigate("/documents");
            getNachRazorpayUrl();
          }}
          text="SUBMIT"
        />
        </>:<h3>Please Complete Agreement to Proceed <a href="/mandate">Click Here</a></h3>}
      </div>
    </div>
  );
}

export default EMandate;
