import React, { useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import styles from "./styles.module.css";
import RightArrow from "../../images/arrow-right.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../redux/slice/userData";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useTimer } from "react-timer-hook";

const Login: React.FC = () => {
  const [state, setState] = useState<"PHONE" | "OTP">("PHONE");
  const [number, setNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const dispatch = useDispatch();

  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(new Date().getTime() + 30 * 1000),
    autoStart: false,
  });

  const navigate = useNavigate();

  const [authToken, setAuthToken] = useSessionStorage("feemonk_data", "");

  useEffect(() => {
    const authenticate = (auth_token: string) => {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${auth_token}`);

      var requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            if (result?.data?.selfieStatus !== 2) {
              navigate("/documents");
            }

            if (result?.data?.bankVerificationStatus !== 2) {
              navigate("/razorpay-mandate");
            }

            if (result?.data?.enachStatus === 4) {
              navigate("/razorpay-mandate");
            }

            if (result?.data?.agreementStatus === 3) {
              navigate("/mandate");
            }

            // console.log(result?.data.userId);
            dispatch(setAuthData(result?.data));
            navigate("/mandate");
          }
        })
        .catch((error) => console.log("error", error));
    };

    if (typeof sessionStorage == "undefined") {
    }

    // const value = JSON.parse(sessionStorage.getItem("feemonk_data") || "{}");

    // if (value.auth_token) {
    //   authenticate(value.auth_token);
    // }

    if (authToken && authToken.value) {
      // console.log("its is", authToken);

      authenticate(authToken.value);
    }
  }, [navigate, dispatch, authToken]);

  const sendOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: number,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          alert(result.message[0]);
        } else if (result.message === "Successful") {
          setState("OTP");
        }
        // console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const verifyOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      mobile: number,
      otp: otp,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/verify-otp`, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        // console.log(result);
        // console.log(result.message);

        if (result.message === "Invalid OTP") {
          alert("Invalid OTP");
        } else if (result.message === "Successful") {
          // await sessionStorage.setItem(
          //   "feemonk_data",
          //   JSON.stringify({
          //     auth_token: result.data,
          //     mobile_number: number,
          //   })
          // );

          setAuthToken({
            value: result.data,
            mob: number,
          });

          authenticate(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const authenticate = (auth_token: string) => {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${authToken && authToken.value}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("IMP", result);

        if (result?.message === "Successful") {
          // console.log(result?.data.userId);
          dispatch(setAuthData(result?.data));
          navigate("/mandate");
        }
      })
      .catch((error) => console.log("error", error));

    setTimeout(() => {
      navigate("/mandate");
    }, 1000);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {/* <h1>00:{seconds}</h1> */}
        {state === "OTP" ? (
          seconds > 0 ? (
            <h1>00:{seconds}</h1>
          ) : (
            <h1>Welcome</h1>
          )
        ) : (
          <h1>Welcome</h1>
        )}
        {state === "OTP" ? (
          <p>Type The Verification Code We've Sent You</p>
        ) : (
          <p>Please confirm your country code and enter your phone number</p>
        )}

        {state === "OTP" ? (
          <div className={styles.inputField}>
            <InputText
              placeholder="OTP"
              type="number"
              value={otp}
              changeHandler={(e) => setOtp(e.target.value)}
            />
          </div>
        ) : (
          <div className={styles.inputField}>
            <InputText
              placeholder="Phone Number"
              type="number"
              value={number}
              changeHandler={(e) => setNumber(e.target.value)}
            />
          </div>
        )}
        {state === "OTP" ? (
          <Button
            onPress={() => {
              verifyOtp();
            }}
            text="SUBMIT"
            imageRight={RightArrow}
          />
        ) : (
          <Button
            onPress={() => {
              sendOtp();
              restart(new Date(new Date().getTime() + 30 * 1000));
            }}
            text="LOGIN"
            imageRight={RightArrow}
          />
        )}
        {state === "OTP" && seconds < 1 && !isRunning && (
          <Button
            onPress={() => {
              sendOtp();
              restart(new Date(new Date().getTime() + 30 * 1000));
            }}
            text="RESEND OTP"
          />
        )}
      </div>
    </div>
  );
};

export default Login;
