import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import { useSessionStorage } from "../../hooks/useSessionStorage";
// import { UserInitialState } from "../../redux/slice/userData";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import DocumentCard from "./DocCard";
import styles from "./styles.module.css";
import { MandateType } from "../../utils/types";
import { SelfieStatus } from "../../utils/config";
let Digio: any;

const DocumentsUpload: React.FC = () => {
  const [mandateStatus, setMandateStatus] = useState<MandateType>({});
  const [authToken] = useSessionStorage("feemonk_data", "");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // const ckycData = useSelector(
  //   (state: { userData: UserInitialState }) =>
  //     state?.userData?.userData?.ckycData
  // );

  useEffect(() => {
    // console.log(flag);

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
      headers: {
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("IMP", data);
        setMandateStatus(data.data);

        if (data.data?.selfieStatus === SelfieStatus.Successful) {
          navigate("/mandate");
        }
      });
  }, [authToken.value, navigate]);

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  function selfieClickHandler() {
    fetchJsFromCDN("https://app.digio.in/sdk/v11/digio.js", ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/one-way-kyc/create`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);

            const { kid, tokenId, id } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: "production",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: kid,
              digioUserIdentifier: authToken.mob || "8106087099",
              digioLoginToken: tokenId,
              is_iframe: true,
              callback: (_digio: any) => {
                // console.log("ALL: ", _digio);

                // var myHeaders = new Headers();
                // myHeaders.append(
                //   "Authorization",
                //   `Bearer ${authToken && authToken.value}`
                // );
                // myHeaders.append("Content-Type", "application/json");

                // var requestOptions: RequestInit = {
                //   method: "POST",
                //   headers: myHeaders,
                //   redirect: "follow",
                // };

                // fetch(
                //   `${process.env.REACT_APP_DASHBOARD_URL}/selfie/updateDetails?entityId=${entityId}&transactionId=${id}`,
                //   requestOptions
                // )
                //   .then((response) => response.text())
                //   .then((result) => {
                //     // console.log(result);
                //     navigate("/mandate");
                //   })
                //   .catch((error) => console.log("error", error));

                if (_digio.error_code === "CANCELLED") {
                  // console.log("Flow cancelled by user");
                  navigate("/mandate");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  navigate("/mandate");
                  throw new Error(_digio.message);
                }
                navigate("/mandate");
                // console.log(_digio);
              },
            });

            // console.log(d);

            d.init();
            d.submit(kid, authToken.mob, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  function getOneWayKycUrl() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/selfie/create`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${authToken.value}`,
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   // TODO: get from use state
      //   // accountNumber: "50100514289789",
      //   // ifscCode: "HDFC0001252",
      //   // accountType: "savings",
      //   // mandateType: "debitcard",
      //   accountNumber: accountNumber.trim(),
      //   ifscCode: ifscCode.trim(),
      //   accountType: radioSelectedAccount,
      //   mandateType: radioSelectedMandate,
      // }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "success") {
          // console.log(res.data.url);
          const redirect_url = 
          window.open(`https://app.digio.in/#/gateway/login/${res.data.entityId}/vI3atY/${authToken.mob}?token_id=${res.data.tokenId}&redirect_url=https://sanctions.feemonk.com/mandate`, "_self", "noopener,noreferrer");

          //   navigation.navigate("NachRazorpay", {
          //     razorpayUrl: res.data.url,
          //   });
        } else {
          if (
            res.message ===
            "Oops. There is an issue at our end. Please contact our support team"
          ) {
            setError(
              "Please check the internet connection. Contact our support team if the error is not resolved."
            );
          }
        }
      });
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1>Upload Document</h1>
        {/* <DocumentCard>
          {ckycData?.result && ckycData.result.IDENTITY_DETAILS.IDENTITY[1] ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <p style={{ fontSize: 16, margin: 5 }}>
                Aadhaar:{" "}
                {ckycData.result.IDENTITY_DETAILS.IDENTITY[1].IDENT_NUM
                  ? ckycData.result.IDENTITY_DETAILS.IDENTITY[1].IDENT_NUM
                  : "Not Uploaded"}
              </p>
              <p style={{ fontSize: 16, margin: 5 }}>
                Pan Number:{" "}
                {ckycData.result.IDENTITY_DETAILS.IDENTITY[0].IDENT_NUM
                  ? ckycData.result.IDENTITY_DETAILS.IDENTITY[0].IDENT_NUM
                  : "Not Uploaded"}
              </p>
            </div>
          ) : (
            <div
              onClick={() => {
                fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", [
                  "Digio",
                ]).then((digio: any) => {
                  Digio = digio[0];
                  let d = new Digio({
                    environment: "sandbox",
                    logo: "yourlogourl",
                    theme: {
                      primaryColor: "#234FDA",
                      secondaryColor: "#234FDA",
                    },
                    digioDocumentId: "KID22101814163942013IOPS5MF1KAN8",
                    digioUserIdentifier: "918763039380",
                    digioLoginToken: "GWT2210181416394357QRK8RWISBI4BK",
                    is_iframe: true,
                    callback: (_digio: any) => {
                      console.log("ALL: ", _digio);

                      if (_digio.error_code === "CANCELLED") {
                        console.log("Flow cancelled by user");
                        return;
                      }
                      if (_digio.error_code !== undefined) {
                        throw new Error(_digio.message);
                      }
                      console.log(_digio);
                    },
                  });

                  console.log(d);

                  d.init();
                  d.submit(
                    "KID22101814163942013IOPS5MF1KAN8",
                    "918763039380",
                    "GWT2210181416394357QRK8RWISBI4BK"
                  );
                });
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#F28F3B",
                  fontWeight: "bold",
                  fontSize: 18,
                  marginBottom: 5,
                }}
              >
                Proof Of Identity
              </p>
              <p
                style={{
                  textAlign: "center",
                  color: "#868FA3",
                  marginBottom: 5,
                }}
              >
                Share your Aadhar and PAN details to complete registration.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 16,
                  flexDirection: "column",
                }}
              >
                // <SelfieImage />
                <img
                  src="id-image.svg"
                  alt=""
                  style={{
                    width: "50%",
                  }}
                />
              </div>
            </div>
          )}
        </DocumentCard> */}
        <DocumentCard>
          <div
            onClick={() => {
              getOneWayKycUrl();
            }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#F28F3B",
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: 5,
              }}
            >
              Take selfie
            </p>
            <p
              style={{
                textAlign: "center",
                color: "#868FA3",
                marginBottom: 5,
              }}
            >
              Please note screenshots, mobile phone bills, and insurance are NOT
              accepted for verification.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: 16,
                flexDirection: "column",
              }}
            >
              {/* <SelfieImage /> */}
              <img
                src="selfie-image.svg"
                alt=""
                style={{
                  width: "50%",
                }}
              />
            </div>
          </div>
        </DocumentCard>
        <Button
          onPress={() => {
            // getCkycData(pan, dob, mob);
            // navigate("/mandate");

            getOneWayKycUrl();
          }}
          text="Proceed"
        />
      </div>
    </div>
  );
};

export default DocumentsUpload;
