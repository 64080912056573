import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import { useSessionStorage } from "../../hooks/useSessionStorage";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import { MandateStatus } from "../../utils/config";
import styles from "./styles.module.css";
import {AgreementStatus} from '../../utils/config'
import ErrorDialog from "../../components/molecules/ErrorDialog";
import {jwtDecode} from 'jwt-decode'

export interface LocationState {
  accountNumber: string;
  ifscCode: string;
}

function RazorpayMandate() {
  const [error, setError] = useState("");
  const [radioSelectedAccount, setRadioSelectedAccount] = useState("SAVINGS");
  const [radioSelectedMandate, setRadioSelectedMandate] =
    useState("NET_BANKING");
  const location = useLocation();

  const lstate = location.state as LocationState;
  const [mandate, setMandate]=useState(false)
  const [state, setState] = useState({
    // accountNumber: lstate?.accountNumber ? lstate?.accountNumber : "",
    // ifscCode: lstate?.ifscCode ? lstate?.ifscCode : "",
    accountNumber: "",
    ifscCode: "",
  });

  const { accountNumber, ifscCode } = state;

  const navigate = useNavigate();

  const [authToken] = useSessionStorage("feemonk_data", "");
  const decode=jwtDecode(authToken.value) as any
  const handleChange = (key: any, val: any) => {
    setState({ ...state, [key]: val });
  };

  function getNachRazorpayUrl() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/nach/register`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${authToken.value}`,
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // TODO: get from use state
        // accountNumber: "50100514289789",
        // ifscCode: "HDFC0001252",
        // accountType: "savings",
        // mandateType: "debitcard",
        accountNumber: accountNumber.trim(),
        ifscCode: ifscCode.trim(),
        accountType: radioSelectedAccount,
        mandateType: radioSelectedMandate,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "success") {
          // console.log(res.data.url);
          window.open(res.data.url, "_blank", "noopener,noreferrer");

          //   navigation.navigate("NachRazorpay", {
          //     razorpayUrl: res.data.url,
          //   });
        } else {
          if (
            res.message ===
            "Oops. There is an issue at our end. Please contact our support team"
          ) {
            setError(
              "Please check the bank details entered. Contact our support team if the error is not resolved."
            );
          }
          if (
            res.message ===
            "Debit card authentication for this bank is currently not available. Please try authenticating with netbanking"
          ) {
            setError(
              "Debit card authentication for this bank is currently not available. Please try authenticating with netbanking"
            );
          }
        }
      });
  }

  useEffect(() => {

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
        headers: {
          Authorization: `Bearer ${authToken.value}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.data.enachStatus === MandateStatus.Successful) {
            navigate("/mandate");
          }
          else if(data.data.agreementStatus===AgreementStatus.Signed)
          {
            setMandate(true);
            
          }
        });

    const apiInterval = setInterval(() => {
      fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
        headers: {
          Authorization: `Bearer ${authToken.value}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.data.enachStatus === MandateStatus.Successful) {
            navigate("/mandate");
          }
          else if(data.data.agreementStatus===AgreementStatus.Signed)
          {
            setMandate(true);
           
          }
        });
    }, 5000);

    return () => {
      clearInterval(apiInterval);
    };
  }, [authToken.value, navigate, authToken]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
      headers: {
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (
          data.data?.mandateStatus?.enachStatus === MandateStatus.Successful
        ) {
          navigate("/mandate");
        }
      });
  }, [authToken.value, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.container}>
       {authToken.value&&mandate?<> <h1>Mandate</h1>

        <div className={styles.inputField}>
          <Label text="Account Number" />
          <InputText
            placeholder="Account Number"
            type="number"
            value={accountNumber}
            changeHandler={(e) => handleChange("accountNumber", e.target.value)}
          />
        </div>

        <div className={styles.inputField}>
          <Label text="IFSC Code" />
          <InputText
            placeholder="IFSC Code"
            type="text"
            value={ifscCode}
            changeHandler={(e) => handleChange("ifscCode", e.target.value)}
          />
        </div>
        <div className={styles.inputField}>
          <Label text="Account Type" />
          <div
            onChange={(event) =>
              setRadioSelectedAccount((event.target as HTMLInputElement).value)
            }
            defaultValue="SAVINGS"
          >
            <input
              type="radio"
              value="SAVINGS"
              name="student"
              checked={radioSelectedAccount === "SAVINGS"}
            />{" "}
            Savings
            <input
              type="radio"
              value="CUURENT"
              name="student"
              checked={radioSelectedAccount === "CUURENT"}
            />{" "}
            Current
          </div>
        </div>
        <div className={styles.inputField}>
          <Label text="How do you want to authenticate your account?" />
          <div
            onChange={(event) =>
              setRadioSelectedMandate((event.target as HTMLInputElement).value)
            }
            defaultValue="NET_BANKING"
          >
            <input
              type="radio"
              value="NET_BANKING"
              name="Net Banking"
              checked={radioSelectedMandate === "NET_BANKING"}
            />{" "}
            Net Banking
            <input
              type="radio"
              value="DEBIT_CARD"
              name="Debit Card"
              checked={radioSelectedMandate === "DEBIT_CARD"}
            />{" "}
            Debit Card
            {/* <input
              type="radio"
              value="AADHAAR"
              name="Aadhaar"
              checked={radioSelectedMandate === "AADHAAR"}
            />{" "}
            Aadhaar */}
          </div>
        </div>
        <Button
          onPress={() => {
            if (accountNumber.trim() === "") {
              setError("Please Enter Account Number");
              return;
            }

            if (ifscCode.trim() === "") {
              setError("Please Enter IFSC Code");
              return;
            }

            getNachRazorpayUrl();
          }}
          text="SUBMIT"
        />
        <p>
          Note: Please Wait for 10 Seconds for Mandate Status to get Updated
          Once It is Done.
        </p>
        </>
        :<h3>Please Complete Agreement to Proceed <a href="/mandate">Click Here</a></h3>}
      </div>
       

      {error.trim() && (
        <ErrorDialog error={error} onClose={() => setError(" ")} />
      )}
    </div>
  );
}

export default RazorpayMandate;
