import React, { useState } from "react";
import styles from "./index.module.css";
import SideBar from "../../components/molecules/SideBar";
import Button from "../../components/atoms/Button";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useNavigate } from "react-router-dom";

let Digio: any;

function ReversePennydrop() {
  const [authToken] = useSessionStorage("feemonk_data", "");
  const [redirectLink, setRedirectLink] = useState("");
  const navigate = useNavigate();

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  function reversePennyDropHandler() {
    fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", ["Digio"])
      .then((digio: any) => {
        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations/reverse-pennydrop/initiate`, {
          headers: {
            Accept: "application/JSON",
            "Content-Type": "application/JSON",
            Authorization: `Bearer ${authToken.value}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((result: any) => {
            if (result?.error) {
              throw new Error(result?.error);
            }
            console.log(result);
            const {
              customer_identifier,
              access_token: { id, entity_id },
            } = result?.result;

            Digio = digio[0];
            let d = new Digio({
              environment: "sandbox",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: entity_id,
              digioUserIdentifier: customer_identifier,
              is_iframe: true,
              callback: (_digio: any) => {
                if (_digio.error_code === "CANCELLED") {
                  // navigate("/thank-you");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  // navigate("/thank-you");
                  throw new Error(_digio.message);
                }

                fetch(
                  `${process.env.REACT_APP_DASHBOARD_URL}/integrations/reverse-pennydrop/status`,
                  {
                    headers: {
                      Accept: "application/JSON",
                      "Content-Type": "application/JSON",
                      Authorization: `Bearer ${authToken.value}`,
                    },
                  }
                )
                  .then((response) => {
                    return response.json();
                  })
                  .then((result: any) => {
                    if (result?.error) {
                      throw new Error(result?.error);
                    }

                    const accountNumber =
                      result?.result?.actions[0]?.details
                        ?.beneficiary_account_no;
                    const userName =
                      result?.result?.actions[0]?.details
                        ?.beneficiary_name_with_bank;
                    const ifscCode = result?.result?.actions[0]?.details?.ifsc;

                    navigate("/razorpay-mandate", {
                      state: { accountNumber, userName, ifscCode },
                    });
                  });

                // navigate("/thank-you");
              },
            });

            console.log(entity_id, customer_identifier, id);

            d.init();
            d.submit(entity_id, customer_identifier, id);
          });
      })
      .catch((error) => {
        return console.error(error);
      });
  }

  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <div className={styles.container}>
          <h1>Reverse Pennydrop</h1>
          <Button
            text={"Start Bank Verification"}
            onPress={() => {
              reversePennyDropHandler();
              // getAARedirectLink();
            }}
          />
          {/* {redirectLink && <p>{JSON.stringify(redirectLink)}</p>} */}
        </div>
      </div>
    </div>
  );
}

export default ReversePennydrop;
