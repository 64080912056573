import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import { useSessionStorage } from "../../hooks/useSessionStorage";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils";
import styles from "./styles.module.css";
import logout from '../../images/logout.png'
import icon_check_circle from "../../images/icons/check-circle.svg";
import { ClipLoader } from 'react-spinners';

import {
  AgreementStatus,
  DigilockerStatus,
  MandateStatus,
  SelfieStatus,
} from "../../utils/config";
import { MandateType } from "../../utils/types";
let Digio: any;

function Mandate() {
  const [mandateStatus, setMandateStatus] = useState<MandateType>({});

  const [flag, setFlag] = useState(false);

  const navigate = useNavigate();

  const [authToken] = useSessionStorage("feemonk_data", "");

  const [loading, setLoading] = useState(false);

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    // console.log(flag);

    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
      headers: {
        Authorization: `Bearer ${authToken.value}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setMandateStatus(data.data);
      });
  }, [authToken.value, flag]);

  // const callAuthApiDelayed = () => {
  //   setTimeout(() => {
  //     fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken.value}`,
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         // console.log(data);
  //         setMandateStatus(data.data);
  //         setLoading(false);
  //       });
  //   }, 4000);
  // };

  // const delayedUpdateDigioCall = (kid: any, id: any) => {
  //   setTimeout(() => {
  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //       "Authorization",
  //       `Bearer ${authToken && authToken.value}`
  //     );
  //     myHeaders.append("Content-Type", "application/json");

  //     var requestOptions: RequestInit = {
  //       method: "POST",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       `${process.env.REACT_APP_DASHBOARD_URL}/digilocker/updateDetails?entityId=${kid}&transactionId=${id}`,
  //       requestOptions
  //     )
  //       .then((response) => response.text())
  //       .then((result) => {
  //         // console.log(result);
  //         setFlag(!flag);
  //         navigate("/mandate");
  //       })
  //       .catch((error) => console.log("error", error));
  //   }, 3000);
  // };

  const disabledCheckDigiLocker = (mandateStatus: MandateType) => {
    if (mandateStatus.kycDone) {
      return true;
    } else if (
      !mandateStatus.kycDone &&
      mandateStatus.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckDigiLockerAfterSelfie = (mandateStatus: MandateType) => {
    if (mandateStatus.digilockerStatus === DigilockerStatus.Successful) {
      return true;
    }
    if (mandateStatus.selfieStatus === SelfieStatus.Failed) {
      return false;
    }
    if (mandateStatus.kycDone) {
      return true;
    } else if (
      !mandateStatus.kycDone &&
      mandateStatus.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckSelfie = (mandateStatus: MandateType) => {
    if (
      !mandateStatus.kycDone &&
      mandateStatus.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus.selfieStatus !== SelfieStatus.Successful) {
      return false;
    }
    return true;
  };

  const disabledCheckAgreement = (mandateStatus: MandateType) => {
    if (
      !mandateStatus.kycDone &&
      mandateStatus.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus.agreementStatus === AgreementStatus.Signed) {
        return true;
      }
      return false;
    }
    if (mandateStatus.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus.agreementStatus !== AgreementStatus.Signed) {
      return false;
    }
    return true;
  };

  const disabledCheckMandate = (mandateStatus: MandateType) => {
    if (
      !mandateStatus.kycDone &&
      mandateStatus.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus.agreementStatus === MandateStatus.Successful) {
        return true;
      }
      return false;
    }
    if (mandateStatus.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus.agreementStatus === AgreementStatus.PendingCoapplicant) {
      return false;
    }
    if (mandateStatus.agreementStatus !== AgreementStatus.Signed) {
      return true;
    }
    if (mandateStatus.enachStatus !== MandateStatus.Successful) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (
      mandateStatus?.selfieStatus === SelfieStatus.Successful &&
      mandateStatus?.enachStatus === MandateStatus.Successful &&
      mandateStatus?.agreementStatus === AgreementStatus.Signed
    ) {
      navigate("/thank-you");
    }
  }, [mandateStatus, navigate]);

  function digilockerHandler() {
    fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/digilocker/create`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);

            const { kid, tokenId, id } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: "production",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              is_iframe: true,
              callback: (_digio: any) => {
                // console.log("ALL: ", _digio);

                // delayedUpdateDigioCall(kid, id);

                if (_digio.error_code === "CANCELLED") {
                  // console.log("Flow cancelled by user");
                  setFlag(!flag);
                  navigate("/mandate");
                  // callAuthApiDelayed();
                  return;
                }
                if (_digio.error_code !== undefined) {
                  setFlag(!flag);
                  navigate("/mandate");
                  // callAuthApiDelayed();
                  throw new Error(_digio.message);
                }

                setFlag(!flag);
                navigate("/mandate");
                // callAuthApiDelayed();

                // console.log(_digio);
              },
            });

            // console.log(d);

            d.init();
            d.submit(kid, authToken.mob, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  function handleLogout() {

    sessionStorage.clear()
    navigate('/login')
    
  }


  const [loaderAgreement, setLoaderAgreement] = useState(false);

  const loanAgreementHandler = () => {
    setLoaderAgreement(true); // Activate loader and disable button
    fetchJsFromCDN("https://app.digio.in/sdk/v10/digio.js", ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/agreement/${
            mandateStatus.isCoapplicant ? "coapplicant-generate" : "generate"
          }`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            const { email, id, tokenId } = result.data;

            const Digio = digio[0];
            let d = new Digio({
              environment: "production",
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: id,
              digioUserIdentifier: email,
              is_iframe: true,
              callback: (_digio: any) => {
                // API call completed, disable loader and enable button
                setLoaderAgreement(false);
                if (_digio.error_code === "CANCELLED") {
                  setFlag(!flag);
                  navigate("/mandate");
                  return;
                }
                if (_digio.error_code !== undefined) {
                  setFlag(!flag);
                  navigate("/mandate");
                  throw new Error(_digio.message);
                }
                setFlag(!flag);
                navigate("/mandate");
              },
            });

            d.init();
            d.submit(id, email, tokenId);
          })
          .catch((error) => {
            // API call failed, disable loader and enable button
            setLoaderAgreement(false);
            console.log("error", error);
          });
      }
    ).catch((error) => {
      // Fetching digio.js failed, disable loader and enable button
      setLoaderAgreement(false);
      console.log("error", error);
    });
  };

  return (
    <>
      {loading && (
        <div className={styles.loadingBox}>
          <p className={styles.loadingText}>Loading...</p>
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.container}>
          <div title="logout" onClick={()=>handleLogout()} className={styles.logout}>
            
            <img style={{width:'18px',height:'18px'}} src={logout}/>
            Logout
          
          </div>
          {/* <h1>Mandate</h1> */}
          <img src="/feemonklogo.png" alt="" className={styles.image} />
          {/* {mandateStatus.digilockerStatus &&
          mandateStatus.digilockerStatus !== 3 && ( */}
          {!mandateStatus.kycDone && (
              <Button
                disabled={disabledCheckDigiLocker(mandateStatus)}
                text="Digilocker KYC"
                onPress={() => {
                  digilockerHandler();
                }}
                imageRight={
                  mandateStatus?.digilockerStatus === 3
                    ? icon_check_circle
                    : null
                }
              />
            )}
          {mandateStatus.selfieStatus !== SelfieStatus.Failed && (
            <Button
              disabled={disabledCheckSelfie(mandateStatus)}
              text="Selfie"
              onPress={() => {
                navigate("/documents");
              }}
              imageRight={
                mandateStatus?.selfieStatus === 3 ? icon_check_circle : null
              }
            />
          )}
          {mandateStatus.selfieStatus === SelfieStatus.Failed && (
            <Button
              disabled={disabledCheckDigiLockerAfterSelfie(mandateStatus)}
              text="Digilocker KYC"
              onPress={() => {
                digilockerHandler();
              }}
              imageRight={
                mandateStatus?.digilockerStatus === 3 ? icon_check_circle : null
              }
            />
          )}
            
           
            <Button
              disabled={disabledCheckAgreement(mandateStatus) || loaderAgreement }
              text="Agreement"
              onPress={loanAgreementHandler}
              imageRight={mandateStatus?.agreementStatus === 3 ? icon_check_circle : null}
            />
             {loaderAgreement && <ClipLoader size={35} color={"#123abc"} loading={loaderAgreement}  />}
          <Button
            disabled={disabledCheckMandate(mandateStatus)}
            text={"e-Mandate"}
            onPress={() => {
              // navigate("/digio-mandate");
              navigate("/razorpay-mandate");
            }}
            imageRight={
              mandateStatus?.enachStatus === 4 ? icon_check_circle : null
            }
          />
          <p className={styles.footerText}>
            Powered By
            <img
              className={styles.footerImage}
              src="https://www.digio.in/images/digio_blue.png"
              alt=""
            />
          </p>
        </div>
      </div>
    </>
  );
}

export default Mandate;
